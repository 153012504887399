interface ApplicationSettings {
  // Required settings
  API_URL: string;
  ORIGIN_API_URL: string;
  API_VERSION: string;
  LEGACY_WEBSITE_URL: string;
  SEGMENT_WRITE_KEY: string;
  OPTIMIZELY_SDK_KEY: string;
  STREAM_API_KEY: string;
  // Datadog RUM settings
  DATADOG_RUM_APP_ID: string;
  DATADOG_RUM_CLIENT_TOKEN: string;
  DATADOG_RUM_SITE: string;
  DATADOG_RUM_SERVICE: string;
  DATADOG_RUM_ENV: string;
  // JWPlayer settings
  JWPLAYER_KEY: string;
  JWPLAYER_LIBRARY_URL: string;
  JWPLAYER_CHROME_CAST_ID: string;
  // Stripe settings
  STRIPE_PUBLISHABLE_KEY: string;
  STRIPE_SECRET_KEY: string;
  // Pages migration settings
  ENABLE_FOR_YOU: boolean;
  ENABLE_LIVE_CLASSES: boolean;
  ENABLE_SEARCH: boolean;
  ENABLE_SHOWCASE: boolean;
  // Optional settings
  ENABLE_THEMES: boolean;
  ENABLE_I18N: boolean;
  LOG_LEVEL: string;
  LOG_HTTP_REQUESTS: boolean;
  // Computed settings:
  isDevelopment: boolean;
  isProduction: boolean;
  isTesting: boolean;
  ADJUST_APP_TOKEN: string;
  ADJUST_ENVIRONMENT: "production" | "sandbox";
}

export const applicationSettings: ApplicationSettings = Object.freeze({
  // Required settings
  API_URL: (process.env.NEXT_PUBLIC_GLO_WEB_API_URL as string).replace(/\/$/, ""),
  ORIGIN_API_URL: (process.env.NEXT_PUBLIC_GLO_WEB_ORIGIN_API_URL as string).replace(/\/$/, ""),
  API_VERSION: (process.env.NEXT_PUBLIC_GLO_WEB_API_VERSION as string) || "4.91.3",
  LEGACY_WEBSITE_URL: (process.env.NEXT_PUBLIC_GLO_WEB_LEGACY_WEBSITE_URL as string).replace(/\/$/, ""),
  SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_GLO_WEB_SEGMENT_WRITE_KEY as string,
  OPTIMIZELY_SDK_KEY: process.env.NEXT_PUBLIC_GLO_WEB_OPTIMIZELY_SDK_KEY as string,
  STREAM_API_KEY: process.env.NEXT_PUBLIC_GLO_WEB_STREAM_API_KEY as string,
  // Datadog RUM settings
  DATADOG_RUM_APP_ID: process.env.NEXT_PUBLIC_GLO_WEB_DATADOG_RUM_APP_ID as string,
  DATADOG_RUM_CLIENT_TOKEN: process.env.NEXT_PUBLIC_GLO_WEB_DATADOG_RUM_CLIENT_TOKEN as string,
  DATADOG_RUM_SITE: process.env.NEXT_PUBLIC_GLO_WEB_DATADOG_RUM_SITE as string,
  DATADOG_RUM_SERVICE: process.env.NEXT_PUBLIC_GLO_WEB_DATADOG_RUM_SERVICE as string,
  DATADOG_RUM_ENV: process.env.NEXT_PUBLIC_GLO_WEB_DATADOG_RUM_ENV as string,
  // JWPlayer settings
  JWPLAYER_KEY: process.env.NEXT_PUBLIC_JWPLAYER_KEY as string,
  JWPLAYER_LIBRARY_URL: process.env.NEXT_PUBLIC_JWPLAYER_LIBRARY_URL as string,
  JWPLAYER_CHROME_CAST_ID: process.env.NEXT_PUBLIC_JWPLAYER_CHROME_CAST_ID as string,
  // Stripe settings
  STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string,
  STRIPE_SECRET_KEY: process.env.NEXT_STRIPE_SECRET_KEY as string,
  // Pages migration settings
  ENABLE_FOR_YOU: (process.env.NEXT_PUBLIC_ENABLE_FOR_YOU as string) === "true",
  ENABLE_LIVE_CLASSES: (process.env.NEXT_PUBLIC_ENABLE_LIVE_CLASSES as string) === "true",
  ENABLE_SEARCH: (process.env.NEXT_PUBLIC_ENABLE_SEARCH as string) === "true",
  ENABLE_SHOWCASE: (process.env.NEXT_PUBLIC_ENABLE_SHOWCASE as string) === "true",
  // Optional settings
  ENABLE_THEMES: (process.env.NEXT_PUBLIC_ENABLE_THEMES as string) === "true",
  ENABLE_I18N: (process.env.NEXT_PUBLIC_ENABLE_I18N as string) === "true",
  LOG_LEVEL: process.env.NEXT_PUBLIC_LOG_LEVEL ?? "info",
  LOG_HTTP_REQUESTS: (process.env.NEXT_PUBLIC_LOG_HTTP_REQUESTS as string) === "true",
  // Computed settings
  isDevelopment: process.env.NODE_ENV === "development",
  isProduction: process.env.NODE_ENV === "production",
  isTesting: process.env.NODE_ENV === "test",
  ADJUST_APP_TOKEN: process.env.NEXT_PUBLIC_ADJUST_APP_TOKEN as string,
  ADJUST_ENVIRONMENT: process.env.NEXT_PUBLIC_ADJUST_ENVIRONMENT as "production" | "sandbox"
});

export const LEGACY_LINKS = {
  MY_ACCOUNT: applicationSettings.LEGACY_WEBSITE_URL + "/settings/my-account",
  PREFERENCES: applicationSettings.LEGACY_WEBSITE_URL + "/settings/preferences",
  CONNECT: applicationSettings.LEGACY_WEBSITE_URL + "/settings/connect",
  DEVICES: applicationSettings.LEGACY_WEBSITE_URL + "/settings/devices",
  COMMUNICATION: applicationSettings.LEGACY_WEBSITE_URL + "/settings/communication",
  SUPPORT: applicationSettings.LEGACY_WEBSITE_URL + "/settings/support",
  REFER: applicationSettings.LEGACY_WEBSITE_URL + "/settings/refer-a-friend",
  PAYMENT: applicationSettings.LEGACY_WEBSITE_URL + "/settings/payment",
  ACCOUNT: applicationSettings.LEGACY_WEBSITE_URL + "/account",
  PAYMENT_HISTORY: applicationSettings.LEGACY_WEBSITE_URL + "/account/payment-history",
  MY_PRACTICE: applicationSettings.LEGACY_WEBSITE_URL + "/my-practice",
  MY_LIBRARY: applicationSettings.LEGACY_WEBSITE_URL + "/library",
  GLO_SUPPORT: "https://support.glo.com/",
  MAIL_TO: "mailto:support@glo.com"
};

export const V2_LINKS = {
  ACCOUNT_SETTINGS: "/v2/glo-guides/account",
  ACCOUNT_PAYMENT: "/v2/glo-guides/payment"
};

const { LEGACY_WEBSITE_URL } = applicationSettings;

export const NEXT_SEO_DEFAULT = {
  metadataBase: new URL(LEGACY_WEBSITE_URL),
  title: "Glo | Online yoga, meditation, & Pilates app for all levels",
  description:
    "Learn from experts and feel empowered on Glo. Get unlimited access to online yoga, Pilates, meditation, and fitness classes for all levels. Start your free trial today!",
  robots: "index,follow",
  authors: [{ name: "Glo Digital, Inc." }],
  keywords:
    "yoga free trial,yoga online,online yoga classes,yoga class online,yoga online,best online yoga classes,yoga subscription,yoga near me classes,best online yoga,yoga for seniors,yoga beginners",
  openGraph: {
    title: "Glo | Online yoga, meditation, & Pilates app for all levels",
    description:
      "Learn from experts and feel empowered on Glo. Get unlimited access to online yoga, Pilates, meditation, and fitness classes for all levels. Start your free trial today!",
    url: LEGACY_WEBSITE_URL,
    siteName: "Glo",
    images: [
      {
        url: `/v2/images/glo-guides/open-graph.jpg`,
        alt: "Glo"
      }
    ]
  },
  verification: { google: "ookLqV41jniLfqze4Nn1MfD3he7C0PFc-J81mKkI9ys" },
  itunes: {
    appId: "1023475268"
  },
  other: {
    "X-UA-Compatible": ["IE=edge"],
    "p:domain_verify": ["483f7c5f43f4c83e782b73f76994c519"]
  }
};

export const GOOD_CORE_STATUSES = ["trial_user", "paid_user", "suspend_before_billing_cycle", "free_user"];
export const BAD_CORE_STATUSES = [
  "guest",
  "hold",
  "suspend_after_billing_cycle",
  "free_period_expired",
  "declined_paid",
  "declined_trial"
];

export const COACH_HELP_KEYS = {
  OTHER: "other",
  SOMETHING_ELSE: "something_else",
  NORMAL: "normal"
};
